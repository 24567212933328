const GATSBY_SOURCE_SUSHISHOP_BASE_URL = (
  process.env.GATSBY_SOURCE_SUSHISHOP_BASE_URL || ""
).replace(/(.+\/?)\/$/, "$1");
const GATSBY_ENV_COUNTRY_CODE = process.env.GATSBY_ENV_COUNTRY_CODE || "FR";
const GATSBY_ENV_COUNTRY_CODE_LOWER_CASE =
  GATSBY_ENV_COUNTRY_CODE.toLowerCase();

const GATSBY_INTL_DEFAULT_LANG = process.env.GATSBY_INTL_DEFAULT_LANG || "fr";

const GATSBY_CONF_ENV = process.env.GATSBY_CONF_ENV || "dev";

const GATSBY_CONF_CONSUMABLE_CATEGORY_ID =
  process.env.GATSBY_CONF_CONSUMABLE_CATEGORY_ID || "";
const GATSBY_CONF_REORDER_CATEGORY_ID =
  process.env.GATSBY_CONF_REORDER_CATEGORY_ID || "";

const GATSBY_CONF_BAGUETTES = process.env.GATSBY_CONF_BAGUETTES || "";
const GATSBY_CONF_SAUCE_SUCREE = process.env.GATSBY_CONF_SAUCE_SUCREE || "";
const GATSBY_CONF_SAUCE_SALEE = process.env.GATSBY_CONF_SAUCE_SALEE || "";
const GATSBY_CONF_GINGEMBRE = process.env.GATSBY_CONF_GINGEMBRE || "";
const GATSBY_CONF_WASABI = process.env.GATSBY_CONF_WASABI || "";
const GATSBY_CONF_HOME_DISPLAY_OTHER_CONTRY = !!parseInt(
  process.env.GATSBY_HOME_DISPLAY_OTHER_CONTRY || "0"
);

const GATSBY_CONF_APP_GOOGLEPLAY_URL =
  process.env.GATSBY_CONF_APP_GOOGLEPLAY_URL || "";
const GATSBY_CONF_APP_APPLESTORE_URL =
  process.env.GATSBY_CONF_APP_APPLESTORE_URL || "";

const GATSBY_CONF_FACEBOOK_URL = process.env.GATSBY_CONF_FACEBOOK_URL || "";
const GATSBY_CONF_INSTAGRAM_URL = process.env.GATSBY_CONF_INSTAGRAM_URL || "";
const GATSBY_CONF_TWITTER_URL = process.env.GATSBY_CONF_TWITTER_URL || "";
const GATSBY_CONF_LINKEDIN_URL = process.env.GATSBY_CONF_LINKEDIN_URL || "";
const GATSBY_CONF_TIKTOK_URL = process.env.GATSBY_CONF_TIKTOK_URL || "";
const GATSBY_CONF_PINTEREST_URL = process.env.GATSBY_CONF_PINTEREST_URL || "";
// recaptcha
const GATSBY_CONF_RECAPTCHA_DESKTOP = !!parseInt(
  process.env.GATSBY_CONF_RECAPTCHA_DESKTOP || "1"
);
const GATSBY_CONF_RECAPTCHA_DESKTOP_ORDER = !!parseInt(
  process.env.GATSBY_CONF_RECAPTCHA_DESKTOP_ORDER || "1"
);
const GATSBY_CONF_RECAPTCHA_DESKTOP_SITE_KEY =
  process.env.GATSBY_CONF_RECAPTCHA_DESKTOP_SITE_KEY || "";

const GATSBY_CONF_ID_CURRENCY_DEFAULT =
  process.env.GATSBY_CONF_ID_CURRENCY_DEFAULT || "";
const GATSBY_CONF_RGPD_ENABLE_CHECK = !!parseInt(
  process.env.GATSBY_CONF_RGPD_ENABLE_CHECK || "0"
);

const GATSBY_CONF_GOOGLE_MAPS_JS_API_KEY =
  process.env.GATSBY_CONF_GOOGLE_MAPS_JS_API_KEY || "";

const GATSBY_CONF_BAGUETTES_REUTILISABLES =
  process.env.GATSBY_CONF_BAGUETTES_REUTILISABLES || "";

const GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN_MODAL = !!parseInt(
  process.env.GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN_MODAL || "0"
);
const GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN = !!parseInt(
  process.env.GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN || "0"
);
const GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN_DELAY = parseInt(
  process.env.GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN_DELAY || "0"
);

const GATSBY_CONF_CAGNOTTE_ENABLED = !!parseInt(
  process.env.GATSBY_CONF_CAGNOTTE_ENABLED || "1"
);

const GATSBY_CONF_SMART_REPLACE_POSTAL_CODE = !!parseInt(
  process.env.GATSBY_SMART_REPLACE_POSTAL_CODE || "0"
);

const GATSBY_CONF_USE_ADVANTAGES = !!parseInt(
  process.env.GATSBY_CONF_USE_ADVANTAGES || "1"
);

const GATSBY_CONF_FACEBOOK_APP_ID_WEB =
  process.env.GATSBY_CONF_FACEBOOK_APP_ID_WEB || "";
const GATSBY_CONF_FACEBOOK_APP_VERSION =
  process.env.GATSBY_CONF_FACEBOOK_APP_VERSION || "";
const GATSBY_CONF_GOOGLE_API_CONNECT_ID =
  process.env.GATSBY_CONF_GOOGLE_API_CONNECT_ID || "";

const GATSBY_CONF_CUSTOMER_ENABLE_TAX_IDENTIFICATION_NUMBER = !!parseInt(
  process.env.GATSBY_CONF_CUSTOMER_ENABLE_TAX_IDENTIFICATION_NUMBER || "0"
);

const GATSBY_CONF_ORDER_STATE_CANCELED_DELIVERY =
  process.env.GATSBY_CONF_ORDER_STATE_CANCELED_DELIVERY || "24";
const GATSBY_CONF_ORDER_STATE_CANCELED_PICKUP =
  process.env.GATSBY_CONF_ORDER_STATE_CANCELED_PICKUP || "28";
const GATSBY_CONF_ENABLE_INVOICE = !!parseInt(
  process.env.GATSBY_CONF_ENABLE_INVOICE || "0"
);
const GATSBY_CONF_COMEIN_RECUP_VALUE = parseFloat(
  process.env.GATSBY_CONF_COMEIN_RECUP_VALUE || "5"
);

//
//
//
const GATSBY_CONF_SHOP_NAME = process.env.GATSBY_CONF_SHOP_NAME || "Sushi Shop";

const GATSBY_CONF_DEFAULT_COUNTRY_PHONE =
  process.env.GATSBY_CONF_DEFAULT_COUNTRY_PHONE || "FR";

const GATSBY_CONF_PHONE_COUNTRY_SHORT_LIST =
  process.env.GATSBY_CONF_PHONE_COUNTRY_SHORT_LIST ||
  GATSBY_CONF_DEFAULT_COUNTRY_PHONE;

//
const GATSBY_CONF_MOD_POSTCODE =
  process.env.GATSBY_CONF_MOD_POSTCODE || "default";
const GATSBY_CONF_MOD_POSTCODE_REGEX =
  process.env.GATSBY_CONF_MOD_POSTCODE_REGEX || "";

const GATSBY_CONF_MOD_POSTCODE_AUTOCOMPLETE = !!parseInt(
  process.env.GATSBY_CONF_MOD_POSTCODE_AUTOCOMPLETE || "1"
);

const GATSBY_CONF_GOOGLE_ADDRESS_SEPARATOR =
  process.env.GATSBY_CONF_GOOGLE_ADDRESS_SEPARATOR || ",";
//
const GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_PRIMARY =
  process.env.GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_PRIMARY || "(.*),?";
const GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_SECONDARY =
  process.env.GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_SECONDARY || "^(.*),";
const GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_DESCRIPTIONEND =
  process.env.GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_DESCRIPTIONEND ||
  "^.*, (.*), .*|.*, .*, (.*), .*$";

const GATSBY_CONF_MAPBOX_ACCESS_TOKEN =
  process.env.GATSBY_MAPBOX_ACCESS_TOKEN ||
  "pk.eyJ1IjoiYWJlZ2FyaW4iLCJhIjoiY2ppYTM0ZGZhMDE4dTN2bDlvbmN3NDUwOSJ9.0Fc_gwOjTNrgO5ZX3CqqVg";

const GATSBY_CONF_MAPBOX_STYLES_URL =
  process.env.GATSBY_CONF_MAPBOX_STYLES_URL ||
  "mapbox://styles/mapbox/streets-v11";
const GATSBY_CONF_ADDRESS_AUTOCOMPLETE_MODE =
  process.env.GATSBY_CONF_ADDRESS_AUTOCOMPLETE_MODE || "default";

const GATSBY_CONF_ADDRESS_REQUIRE_STREET_NUMBER =
  process.env.GATSBY_CONF_ADDRESS_REQUIRE_STREET_NUMBER === "1";
const GATSBY_CONF_ADDRESS_AUTOCOMPLETE_BUFFER_DURATION_MOBILE = parseInt(
  process.env.GATSBY_CONF_ADDRESS_AUTOCOMPLETE_BUFFER_DURATION_MOBILE || "1500"
);
const GATSBY_CONF_ADDRESS_AUTOCOMPLETE_BUFFER_DURATION_DESKTOP = parseInt(
  process.env.GATSBY_CONF_ADDRESS_AUTOCOMPLETE_BUFFER_DURATION_DESKTOP || "500"
);
const GATSBY_CONF_ADDRESS_AUTOCOMPLETE_SHORT_IGNORE = parseInt(
  process.env.GATSBY_CONF_ADDRESS_AUTOCOMPLETE_SHORT_IGNORE || "3"
);

const GATSBY_CONF_IS_AVIS_VERIFIE_ACTIVE = !!parseInt(
  process.env.GATSBY_CONF_IS_AVIS_VERIFIE_ACTIVE || "0"
);

const GATSBY_CONF_ENERGY_DECIMALS_SIGN =
  process.env.GATSBY_CONF_ENERGY_DECIMALS_SIGN || ",";
const GATSBY_CONF_ENERGY_UNIT = process.env.GATSBY_CONF_ENERGY_UNIT || "%s kJ.";
const GATSBY_CONF_ENERGY_ROUND_ZERO = !!parseInt(
  process.env.GATSBY_CONF_ENERGY_ROUND_ZERO || "1"
);
const GATSBY_CONF_ENERGY_CONVERSION_RATE = parseInt(
  process.env.GATSBY_CONF_ENERGY_CONVERSION_RATE || "1"
);
const GATSBY_CONF_ENERGY_DECIMALS = parseInt(
  process.env.GATSBY_CONF_ENERGY_DECIMALS || "0"
);

const GATSBY_CONF_LUNCH_DINER_SERVICE_THRESHOLD_TIME =
  process.env.GATSBY_CONF_LUNCH_DINER_SERVICE_THRESHOLD_TIME || "16:00:00";

const GATSBY_CONF_DIFFER_MAX_WEEKS = parseInt(
  process.env.GATSBY_CONF_DIFFER_MAX_WEEKS || "5"
);

const GATSBY_CONF_CVC_CART_MIN_DISPLAY = parseInt(
  process.env.GATSBY_CONF_CVC_CART_MIN_DISPLAY || "100"
);

const GATSBY_NO_COMEIN = !!parseInt(process.env.GATSBY_NO_COMEIN || "0");
const GATSBY_CONF_MY_SUSHI_SHOP_ENABLED = !!parseInt(
  process.env.GATSBY_CONF_MY_SUSHI_SHOP_ENABLED || "0"
);
const GATSBY_CONF_MY_SUSHI_SHOP_HIDE_PROGRESS = !!parseInt(
  process.env.GATSBY_CONF_MY_SUSHI_SHOP_HIDE_PROGRESS || "0"
);
const GATSBY_CONF_MY_SUSHI_SHOP_ORDER_BONUS_COUNT = parseInt(
  process.env.GATSBY_CONF_MY_SUSHI_SHOP_ORDER_BONUS_COUNT || "4"
);
const GATSBY_CONF_MY_SUSHISHOP_ORDER_BONUS_MIN_TOTAL_PRICE = parseFloat(
  process.env.GATSBY_CONF_MY_SUSHISHOP_ORDER_BONUS_MIN_TOTAL_PRICE || "20"
);
const GATSBY_CONF_MY_SUSHISHOP_ORDER_BONUS_VALUE = parseFloat(
  process.env.GATSBY_CONF_MY_SUSHISHOP_ORDER_BONUS_VALUE || "5"
);

export {
  GATSBY_CONF_ENV,
  GATSBY_ENV_COUNTRY_CODE,
  GATSBY_ENV_COUNTRY_CODE_LOWER_CASE,
  GATSBY_SOURCE_SUSHISHOP_BASE_URL,
  GATSBY_INTL_DEFAULT_LANG,
  //
  GATSBY_CONF_SHOP_NAME,
  GATSBY_CONF_HOME_DISPLAY_OTHER_CONTRY,
  //
  GATSBY_CONF_CONSUMABLE_CATEGORY_ID,
  GATSBY_CONF_REORDER_CATEGORY_ID,
  //
  //
  GATSBY_CONF_APP_APPLESTORE_URL,
  GATSBY_CONF_APP_GOOGLEPLAY_URL,
  //
  GATSBY_CONF_FACEBOOK_URL,
  GATSBY_CONF_INSTAGRAM_URL,
  GATSBY_CONF_TWITTER_URL,
  GATSBY_CONF_LINKEDIN_URL,
  GATSBY_CONF_TIKTOK_URL,
  GATSBY_CONF_PINTEREST_URL,
  //
  GATSBY_CONF_RECAPTCHA_DESKTOP,
  GATSBY_CONF_RECAPTCHA_DESKTOP_ORDER,
  GATSBY_CONF_RECAPTCHA_DESKTOP_SITE_KEY,
  //
  GATSBY_CONF_MOD_POSTCODE,
  GATSBY_CONF_MOD_POSTCODE_REGEX,
  GATSBY_CONF_MOD_POSTCODE_AUTOCOMPLETE,
  GATSBY_CONF_SMART_REPLACE_POSTAL_CODE,
  GATSBY_CONF_GOOGLE_ADDRESS_SEPARATOR,
  GATSBY_CONF_ID_CURRENCY_DEFAULT,
  GATSBY_CONF_RGPD_ENABLE_CHECK,
  GATSBY_CONF_BAGUETTES_REUTILISABLES,
  GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN_MODAL,
  GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN,
  GATSBY_CONF_BAGUETTES_REUTILISABLES_WARN_DELAY,
  //
  GATSBY_CONF_CAGNOTTE_ENABLED,
  GATSBY_CONF_USE_ADVANTAGES,
  //
  GATSBY_CONF_BAGUETTES,
  GATSBY_CONF_SAUCE_SUCREE,
  GATSBY_CONF_SAUCE_SALEE,
  GATSBY_CONF_GINGEMBRE,
  GATSBY_CONF_WASABI,
  //
  GATSBY_CONF_FACEBOOK_APP_ID_WEB,
  GATSBY_CONF_FACEBOOK_APP_VERSION,
  GATSBY_CONF_GOOGLE_API_CONNECT_ID,
  //
  GATSBY_CONF_PHONE_COUNTRY_SHORT_LIST,
  GATSBY_CONF_DEFAULT_COUNTRY_PHONE,
  //
  GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_PRIMARY,
  GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_SECONDARY,
  GATSBY_CONF_GOOGLE_ADDRESS_REG_EXP_DESCRIPTIONEND,
  GATSBY_CONF_GOOGLE_MAPS_JS_API_KEY,
  GATSBY_CONF_ADDRESS_AUTOCOMPLETE_MODE,
  GATSBY_CONF_ADDRESS_AUTOCOMPLETE_BUFFER_DURATION_MOBILE,
  GATSBY_CONF_ADDRESS_AUTOCOMPLETE_BUFFER_DURATION_DESKTOP,
  GATSBY_CONF_ADDRESS_AUTOCOMPLETE_SHORT_IGNORE,
  GATSBY_CONF_ADDRESS_REQUIRE_STREET_NUMBER,
  GATSBY_CONF_MAPBOX_ACCESS_TOKEN,
  GATSBY_CONF_MAPBOX_STYLES_URL,
  //
  GATSBY_CONF_CUSTOMER_ENABLE_TAX_IDENTIFICATION_NUMBER,
  //
  GATSBY_CONF_ORDER_STATE_CANCELED_DELIVERY,
  GATSBY_CONF_ORDER_STATE_CANCELED_PICKUP,
  GATSBY_CONF_ENABLE_INVOICE,
  GATSBY_CONF_COMEIN_RECUP_VALUE,
  //
  GATSBY_CONF_IS_AVIS_VERIFIE_ACTIVE,
  //
  GATSBY_CONF_ENERGY_DECIMALS_SIGN,
  GATSBY_CONF_ENERGY_UNIT,
  GATSBY_CONF_ENERGY_ROUND_ZERO,
  GATSBY_CONF_ENERGY_CONVERSION_RATE,
  GATSBY_CONF_ENERGY_DECIMALS,
  //
  GATSBY_CONF_LUNCH_DINER_SERVICE_THRESHOLD_TIME,
  GATSBY_CONF_DIFFER_MAX_WEEKS,
  GATSBY_CONF_CVC_CART_MIN_DISPLAY,
  //
  GATSBY_NO_COMEIN,
  GATSBY_CONF_MY_SUSHI_SHOP_ENABLED,
  GATSBY_CONF_MY_SUSHI_SHOP_ORDER_BONUS_COUNT,
  GATSBY_CONF_MY_SUSHISHOP_ORDER_BONUS_MIN_TOTAL_PRICE,
  GATSBY_CONF_MY_SUSHISHOP_ORDER_BONUS_VALUE,
  GATSBY_CONF_MY_SUSHI_SHOP_HIDE_PROGRESS,
};
